// AnotherForm.js

import React from 'react';

const AnotherForm = () => {
  return (
    <div>
      <h2>Another Form</h2>
      {/* Add your another form fields and logic here */}
    </div>
  );
}

export default AnotherForm;
