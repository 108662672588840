// Home.jsx

import React from 'react';
import './Home.css';
import DGMadamImage from '../assets/DG-Madam.jpg'; // Import the image using correct path
import Image2 from '../assets/achievment.jpg'; // Import the image using correct path
import Image3 from '../assets/WomensDay.jpg';



const Home = () => {
  return (
    <div className="home-container">
      <div className="image-description">
        <img src={DGMadamImage} alt="Image 1" className="image" />
        <p className="description"  style={{ fontSize: '20px' }}>
          SUMMARY<br />
          * Singh, a 1989-batch IPS officer of Rajasthan cadre, is currently the Special Director General of CISF<br />
          * The Appointments Committee of the Cabinet has approved Nina Singh's appointment to the post of DG, CISF till July 31, 2024<br />
          * Nina Singh has also served as the Joint Director of CBI previously
        </p>
      </div>
      <div className="image-description">
        <p className="description"  style={{ fontSize: '20px' }}>
        
        Discipline is the soul of an army. This makes small numbers formidable, buys the success of the weak, and respects all.

        </p>
        <img src={Image2} alt="Image 2" className="image"  style={{ width: '400px', height: '500px' }}/>
      </div>


      <div className="image-description" >
        
        <img src={Image3} alt="Image 3" className="image"  style={{ width: '400px', height: '400px' }}/>
        <p className="description"  style={{ fontSize: '25px' }}>
        “Women should be celebrated every day…It doesn’t just honor the iconic women who have changed history, but encourages new generations to dream big and know that anything is possible.”
        </p>
       

      </div>
      {/* Add more image-description pairs as needed */}
    </div>
  );
};

export default Home;
