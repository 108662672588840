// App.js
import React from 'react';
import Login from './components/Login';
import Dashboard from './MainPage/Dashboard';
import './App.css'

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="app">
      {!isLoggedIn ? <Login setIsLoggedIn={setIsLoggedIn} /> : <Dashboard handleLogout={handleLogout} />}
    </div>
  );
}

export default App;
