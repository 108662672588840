import React, { useState } from 'react';
import Home from './Home';
import Services from './Services';
import Gallery from './Gallery';
import About from './About';
import Contact from './Contact';
import './Dashboard.css';

const Dashboard = ({ handleLogout }) => {
  const [activeComponent, setActiveComponent] = useState('home');

  const handleClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <div className="dashboard">
      <h2>Welcome to Naresh's World</h2>
      <button className="logout-btn" onClick={handleLogout}>Logout</button>
      <nav>
        <ul>
          <li className={activeComponent === 'home' ? 'active' : ''} onClick={() => handleClick('home')}><a href="#">Home</a></li>
          <li className={activeComponent === 'services' ? 'active' : ''} onClick={() => handleClick('services')}><a href="#">Services</a></li>
          <li className={activeComponent === 'gallery' ? 'active' : ''} onClick={() => handleClick('gallery')}><a href="#">Gallery</a></li>
          <li className={activeComponent === 'about' ? 'active' : ''} onClick={() => handleClick('about')}><a href="#">About</a></li>
          <li className={activeComponent === 'contact' ? 'active' : ''} onClick={() => handleClick('contact')}><a href="#">Contact</a></li>
        </ul>
      </nav>
      <div className="content">
        {activeComponent === 'home' && <Home />}
        {activeComponent === 'services' && <Services />}
        {activeComponent === 'gallery' && <Gallery />}
        {activeComponent === 'about' && <About />}
        {activeComponent === 'contact' && <Contact />}
      </div>
    </div>
  );
};

export default Dashboard;
