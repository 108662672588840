import React, { useState } from 'react';
import './LeaveForm.css'; // Import CSS file

function LeaveForm() {
  const [employeeId, setEmployeeId] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [leaveFromDate, setLeaveFromDate] = useState('');
  const [leaveToDate, setLeaveToDate] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [reason, setReason] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send form data to PHP script
    const response = await fetch('submit_form.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employeeId,
        employeeName,
        leaveFromDate,
        leaveToDate,
        joiningDate,
        reason
      })
    });

    if (response.ok) {
      console.log('Form submitted successfully!');
      setSubmitted(true);
    } else {
      console.error('Failed to submit form.');
    }
  };

  return (
    <div className="leave-form">
      <h2>Leave Form</h2>
      {submitted ? (
        <div className="success-message">
          <p>Form submitted successfully!</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Employee ID:
            <input
              type="text"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Employee Name:
            <input
              type="text"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Leave From Date:
            <input
              type="date"
              value={leaveFromDate}
              onChange={(e) => setLeaveFromDate(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Leave To Date:
            <input
              type="date"
              value={leaveToDate}
              onChange={(e) => setLeaveToDate(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Joining Date:
            <input
              type="date"
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Reason for Leave:
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}

export default LeaveForm;