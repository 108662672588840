import React, { useState } from 'react';
import './Services.css';
import LeaveForm from './LeaveForm';
import AnotherForm from './AnotherForm';


const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  return (
    <div>
      <h2>Services</h2>
      <div className="service-buttons-container">
        <button className="service-button" onClick={() => handleServiceClick("Leave Application")}>Leave Application</button>
        <button className="service-button" onClick={() => handleServiceClick("Service 2")}>Service 2</button>
      </div>
      <div className="service-content">
        {selectedService === "Leave Application" && (
          <div>
            <LeaveForm/>
            
          </div>
        )}
        {selectedService === "Service 2" && (
          <div>
            <AnotherForm/>
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;